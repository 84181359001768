import React from 'react';
import { Helmet } from 'react-helmet';
import SlickSlider from "../components/uielements/slider/slider";
import faviconx16 from '../assets/images/favicon_io/favicon-16x16.png';
import faviconx32 from '../assets/images/favicon_io/favicon-32x32.png';
import appleTouchIcon from '../assets/images/favicon_io/apple-touch-icon.png';
import androidChrome192 from '../assets/images/favicon_io/android-chrome-192x192.png';
import androidChrome512 from '../assets/images/favicon_io/android-chrome-512x512.png';
import CategoryContainer from '../components/category/categoriesContainer';
import CategoryElement from '../components/category/category';
import Header from "../components/uielements/header/header";
import Profile from "../components/about-profile/profile";
import Footer from "../components/footer/footer";
import '../assets/styles.css';


const Application = ({ data }) => {
    const { categories } = data;
    const { allFile: { edges } } = data;
    const { mainSlider } = edges[0].node.childMarkdownRemark.frontmatter
    return (
        <div className="main-page">
            <Helmet>
                <title>Elizaveta Vornicoglo Interior Design</title>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta http-equiv="X-UA-Compatible" content="ie=edge" />
                <meta name="title" content="Elizaveta Vornicoglo Interior Design" />
                <meta name="description" content="Elizaveta Vornicoglo Interior Design" />
                <link rel="apple-touch-icon" href={appleTouchIcon} type="image/x-icon" />
                <link rel="shortcut icon" sizes="16x16" href={faviconx16} type="image/x-icon" />
                <link rel="shortcut icon" sizes="32x32" href={faviconx32} type="image/x-icon" />
                <link rel="shortcut icon" sizes="192x192" href={androidChrome192} type="image/x-icon" />
                <link rel="shortcut icon" sizes="512x512" href={androidChrome512} type="image/x-icon" />
            </Helmet>
            <Header />
            <SlickSlider sources={mainSlider} />
            <CategoryContainer>
               {categories.edges.map((category, index) => <CategoryElement key={index} data={category} />)}
            </CategoryContainer>
            <Profile />
            <Footer />
        </div>
    )
}

export default Application
export const pageQuery = graphql`
query  {
  allFile(filter: {relativeDirectory: {eq: "main"}}) {
    edges {
      node {
        childMarkdownRemark {
          frontmatter {
            title
            mainSlider
          }
        }
      }
    }
  },
  categories: allFile(filter: {relativeDirectory: {eq: "categories"}}) {
    edges {
      node {
        childMarkdownRemark {
          frontmatter {
            category
            cover
            images
            path
            title
          }
        }
      }
    }
  }
}
`