import React from "react";
import instaGramIcon from '../../assets/images/instagram.svg';
import phoneIcon from '../../assets/images/iconmonstr-phone-3.svg';
import emailIcon from '../../assets/images/iconmonstr-mail-thin.svg';
import './profile.scss';

export default function Profile() {
    return (
        <div className="profile">
            <img className="profile__picture"
                src="https://res.cloudinary.com/dcvrsyx6b/image/upload/q_auto/v1578845353/BR9A2793_k76n5y.jpg"
            />
            <div className="profile__contacts">
                <h2>Contacts</h2>  
                <div className="contacts-container">
                    <a href="https://www.instagram.com/evornicoglo_design/">
                        <img src={instaGramIcon} width="32px" alt="" />
                        evornicoglo_design
                    </a>
                    <a href="tel: +37369053881">
                        <img src={phoneIcon} width="32px" alt="" />
                        +373 69 053 881
                    </a>
                    <a href="mailto: evornicoglo@gmail.com">
                        <img src={emailIcon} width="32px" alt="" />
                        evornicoglo@gmail.com
                    </a>
                </div>
            </div>
        </div>
    )
}