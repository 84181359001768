import React from "react";
import { Link } from "gatsby";
import { cloudinaryImageQualityModify } from "../utils/utils";


export default function Category(props) {
    const { category, path, title, cover} = props.data.node.childMarkdownRemark.frontmatter
    if(category === 'ALL') {
        return null
    }
    return (
        <div className="category-preview">
            <Link to={path}>
                <img width="300" height="200" src={cloudinaryImageQualityModify(cover)} />
                <span>{title}</span>
            </Link>
        </div>
    )
}