import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { cloudinaryImageQualityModify } from "../../utils/utils";

export default function SlickSlider(props) {
    const settings = {
        dots: false,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        fade: true,
        pauseOnHover: false,
        arrows: false
    };

    return (
        <Slider {...settings}>
            {props.sources.map(item => {
                return (
                <div
                    key={item}
                >
                    <div style={{
                    width: '100%',
                    height: '100vh',
                    backgroundImage: `url(${cloudinaryImageQualityModify(item)})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}>

                    </div>
                </div>
                )
            })}
        </Slider>
    )
}